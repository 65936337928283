import AddDrawer from '../../Components/AddDrawer/AddDrawer';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AdminMenu from '../../Components/AdminMenu/AdminMenu';
import CalendarAddAppointmentDrawer from '../../Components/CalendarAddAppointmentDrawer/CalendarAddAppointmentDrawer';
import Overlay from '../../Components/Overlay/Overlay';
import StellestRegistrationForm from '../../Components/StellestRegistrationForm/StellestRegistrationForm';
import Constants from '../../constants';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import PageviewBuilder from '../../pageviewBuilder';
import AppointmentService from '../../Services/appointmentService';
import AuthService from '../../Services/authService';
import ContentManagementService from '../../Services/contentManagementService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import LocationConfigService from '../../Services/locationConfigService';
import NotificationService from '../../Services/notificationsService';
import SignalRHubService from '../../Services/signalRHubService';
import SiteConfigService from '../../Services/siteConfigService';
import SupportInfoService from '../../Services/supportInfoService';
import Session from '../../session';
import Storage from '../../storage';
import Utils from '../../utils';
import './Calendar.scss';
import CalendarDayViewer from './CalendarDayViewer';
import CalendarFooter from './CalendarFooter';
import CalendarPane from './CalendarPane';
import CalendarQueue from './CalendarQueue';
import CalendarQueueMobile from './CalendarQueueMobile';
import CalendarScheduler from './CalendarScheduler';

const pBuilder = new PageviewBuilder();
const eBuilder = new EventBuilder();

/**
 * Represents the entirety of the admin scheduler calendar.
 */
class Calendar extends Component {
  /**
   * Initializes a new instance of the Calendar component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      appointments: [],
      appointmentTypes: [],
      currDateTime: null,
      dayDuration: 60, // One hour day blocks
      isPaneCollapsed: true,
      isReadyToRender: false,
      isStellestFormOpen: true,
      locationConfig: {
        appointmentTypes: [],
        countrySettings: [],
        locationSettings: [],
        locationId: 0,
        resources: [],
        schedule: {
          schedules: [],
        },
        storeInformation: {
          countryCode: '',
          languageTag: '',
          name: '',
        },
      },
      phoneCountryCode: '',
      resources: [],
      showLoadingOverlay: false,
      featureConfiguration: {},
    };
    this._supportInfo = {
      email: '',
      phone: '',
    };
    this._siteConfigService = new SiteConfigService();
    this._appointmentService = new AppointmentService();
    this._supportInfoService = new SupportInfoService();
    this._notificationService = new NotificationService();
    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
    this._featureConfigurationService = new FeatureConfigurationService();

    pBuilder.pageview(pBuilder.Page.portal);
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      const isAuthenticated = await AuthService.authenticateUser();

      if (!isAuthenticated) {
        AuthService.redirectToLogin();
      }
    } catch {
      AuthService.redirectToLogin();
    }

    try {
      this._toggleLoadingOverlay();

      await this._getSiteConfig();
      await this._getLocationConfig();
      this._checkOnboardingStatus();
      await this._getSupportInfo();
      await this._getPhoneCountryCode();
      await this._setI18nLanguage();

      const featureConfiguration =
        await this._featureConfigurationService.getFeatureConfiguration(
          process.env.REACT_APP_ENVIRONMENT
        );
      this.setState(() => ({
        featureConfiguration: featureConfiguration,
      }));

      const dateString = Utils.getUrlParam('date');
      if (dateString) {
        const passedDate = new momentLocaleWrapper(
          dateString.toUpperCase()
        ).local();
        await this.onCurrDateTimePassedInUrl(passedDate);
      } else {
        await this._getAppointments();
      }

      await this._setupSubscriptions();
      SignalRHubService.setupCommunicationHub();

      Events.emit(Constants.Events.pageReady);

      this.setState(() => ({ isReadyToRender: true }));
      this._toggleLoadingOverlay();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Executes when the component is unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeListener(Constants.Events.onLogout);
    Events.removeListener(Constants.Events.onToggleCalendarPane);
    Events.removeListener(Constants.Events.newAppointment);
    Events.removeListener(Constants.Events.resourceChange);
    Events.removeListener(Constants.Events.resourcesUpdated);
    Events.removeListener(Constants.Events.locationChanged);
    SignalRHubService.closeCommunicationHub();
  }

  _getAppointments = async (currDateTime) => {
    try {
      const appointments = await this._appointmentService.getAppointments(
        currDateTime
      );
      Utils.formatAppointments(appointments);

      this.setState((prevState) => {
        const { appointmentTypes } = prevState;

        const updatedAppointments = appointments.map((appt) => {
          const foundApptType = appointmentTypes.find(
            (apptType) =>
              apptType.locationAppointmentTypeId === appt.appointmentType.id
          );
          appt.isVisible = foundApptType?.isChecked;
          return appt;
        });

        return {
          appointments: updatedAppointments,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  _getLocationConfig = async () => {
    try {
      const locationConfig =
        await this._locationConfigService.getLocationConfig();
      this._setupLocationConfig(locationConfig);
    } catch (error) {
      console.error(error);
    }
  };

  _getPhoneCountryCode = async () => {
    try {
      const phoneCountryCode =
        await this._contentManagementService.getPhoneCountryCode(
          this.state.locationConfig.storeInformation.countryCode
        );

      this.setState(() => ({ phoneCountryCode: phoneCountryCode }));
    } catch (error) {
      console.error(error);
    }
  };

  _getSiteConfig = async () => {
    try {
      await this._siteConfigService.getSiteConfig();
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);
      }
    }
  };

  _getSupportInfo = async () => {
    try {
      this._supportInfo = await this._supportInfoService.getSupportInfo(
        this.state.locationConfig.storeInformation.countryCode
      );
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);
      }
    }
  };

  /**
   * Executes when an appointment type changes.
   * @param {Number} id The appointment type id.
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value.
   */
  onAppointmentTypeChange = (id, path, value) => {
    this.setState((prevState) => {
      const { appointments, appointmentTypes } = prevState;
      let updatedAppointments = [];
      const updatedAppointmentTypes = appointmentTypes.map((apptType) => {
        if (apptType.id === id) {
          Utils.update(apptType, path, value);

          // Hide the appointments on the scheduler that match the appointment type.
          updatedAppointments = appointments.map((appt) => {
            if (
              appt.appointmentType.id === apptType.locationAppointmentTypeId
            ) {
              appt.isVisible = apptType.isChecked;
            }

            return appt;
          });
        }

        return apptType;
      });

      return {
        appointments: updatedAppointments,
        appointmentTypes: updatedAppointmentTypes,
      };
    });
  };

  /**
   * Executes when the current date time changes.
   * @param {Object} currDateTime The current date time.
   */
  onCurrDateTimeChange = async (currDateTime) => {
    this._toggleLoadingOverlay();
    await this._getAppointments(currDateTime);
    this.setState(() => ({ currDateTime: currDateTime }));
    this._toggleLoadingOverlay();
  };

  onCurrDateTimePassedInUrl = async (currDateTime) => {
    await this._getAppointments(currDateTime);
    this.setState(() => ({ currDateTime: currDateTime }));
  };

  /**
   * Executes when the day viewer duration changes.
   * @param {Number} duration The duration to view the scheduler calendar.
   */
  onDayViewerDurationChange = (duration) => {
    if (duration) {
      this.setState(() => ({ dayDuration: duration }));
    }
  };

  /**
   * Executes when an appointment needs to be deleted.
   * @param {Object} appointment The appointment.
   */
  onDeleteAppointment = (appointment) => {
    if (appointment) {
      try {
        appointment.currentStatus = Enums.AppointmentStatus.cancelled;

        this._appointmentService.saveAppointment(appointment);
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Executes when an appointment is moved to a scheduled status from the queue.
   * @param {Object} appointment The appointment.
   * @param {Object} startDate The start date of the appointment.
   * @param {Object} resource The resources that the appointment is assigned to.
   */
  onMoveAppointmentToScheduled = (appointment, startDate, resource) => {
    if (appointment) {
      try {
        let appointmentToSchedule = this.state.appointments.find(
          (appt) => appt.id === appointment.id
        );

        if (appointmentToSchedule) {
          appointmentToSchedule.created = new Date();
          appointmentToSchedule.resource = { ...resource };
          appointmentToSchedule.startDate = startDate;
          appointmentToSchedule.resourceId = resource.resourceId;
          appointmentToSchedule.currentStatus =
            Enums.AppointmentStatus.scheduled;
          appointmentToSchedule.endDate = momentLocaleWrapper(startDate)
            .add(appointmentToSchedule.appointmentType.length, 'minutes')
            .toDate();

          this._appointmentService.saveAppointment(appointmentToSchedule);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Executes when an appointment is marked as a no show.
   * @param {Object} appointment The appointment.
   */
  onMarkAppointmentAsNoShow = (appointment) => {
    if (appointment) {
      try {
        appointment.currentStatus = Enums.AppointmentStatus.noShow;

        this._appointmentService.saveAppointment(appointment);
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Executes when the resources change.
   * @param {Number} id The id of the resource.
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value.
   */
  onResourceChange = (id, path, value) => {
    this.setState((prevState) => {
      const updatedResources = prevState.resources.map((res) => {
        if (res.resourceId === id) {
          Utils.update(res, path, value);
        }

        return res;
      });

      return {
        resources: updatedResources,
      };
    });
  };

  /**
   * Executes when an appointment needs to be updated.
   * @param {Object} appointment The appointment.
   */
  onUpdateAppointment = (appointment) => {
    if (appointment) {
      try {
        this._appointmentService.saveAppointment(appointment);
      } catch (error) {
        console.error(error);
      }
    }
  };

  _setI18nLanguage = async () => {
    const { languageTag } = this.state.locationConfig.storeInformation;

    Storage.setItem(Constants.langTag, languageTag);

    await this._contentManagementService.loadLocalizations(languageTag);

    momentLocaleWrapper.locale(languageTag);

    this.setState(() => ({ currDateTime: momentLocaleWrapper() }));

    return await this.props.i18n.changeLanguage(languageTag);
  };

  _setupLocationConfig = (locationConfig) => {
    if (locationConfig) {
      const resources = cloneDeep(locationConfig.resources);
      const appointmentTypes = cloneDeep(locationConfig.appointmentTypes);
      const appliedHolidays = locationConfig.appliedHolidays.map((holiday) => {
        holiday.startDate = Utils.convertApiDateFormatToDate(holiday.startDate);
        holiday.endDate = Utils.convertApiDateFormatToDate(holiday.endDate);

        return holiday;
      });

      this._appliedHolidays = appliedHolidays;

      Utils.formatResources(resources);
      Utils.formatAppointmentTypes(appointmentTypes);
      Session.setItem(Constants.currResources, locationConfig.resources);
      Session.setItem(
        Constants.currCountry,
        locationConfig.storeInformation.countryCode
      );

      this.setState(() => ({
        appointmentTypes: appointmentTypes,
        locationConfig: cloneDeep(locationConfig),
        resources: resources,
      }));
    }
  };

  _checkOnboardingStatus = () => {
    if (!this.state.locationConfig.agreementSigned) {
      window.location = `/termsuse`;
    }
  };

  async _setupSubscriptions() {
    Events.on(Constants.Events.onLogout, () => {
      AuthService.logoutUser();
    });
    Events.on(Constants.Events.onToggleCalendarPane, () => {
      this.setState((prevState) => ({
        isPaneCollapsed: !prevState.isPaneCollapsed,
      }));
    });
    Events.on(Constants.Events.newAppointment, (appointment) => {
      if (
        appointment &&
        !this.state.appointments.some((appt) => appt.id === appointment.id)
      ) {
        Utils.formatAppointment(appointment);

        this.setState((prevState) => ({
          appointments: prevState.appointments.concat(appointment),
        }));
      }
    });
    Events.on(Constants.Events.appointmentUpdated, (appointment) => {
      if (appointment) {
        this.setState((prevState) => {
          const updatedAppointments = prevState.appointments.map((appt) => {
            if (appt.id === appointment.id) {
              const updatedAppointment = cloneDeep(appointment);

              Utils.formatAppointment(updatedAppointment);

              return updatedAppointment;
            } else {
              return appt;
            }
          });

          return {
            appointments: updatedAppointments,
          };
        });
      }
    });
    Events.on(Constants.Events.locationConfigUpdate, (locationConfig) => {
      this._setupLocationConfig(locationConfig);
    });
    Events.on(Constants.Events.resourceChange, (resourceId) => {
      if (resourceId) {
        this.setState((prevState) => {
          const updatedResources = prevState.resources.map((res) => {
            if (res.isSelected) {
              res.isSelected = false;
            }

            if (res.resourceId === resourceId) {
              res.isSelected = true;
            }

            return res;
          });

          Session.setItem(Constants.currResources, updatedResources);
          Events.emit(Constants.Events.resourcesUpdated, updatedResources);

          return {
            resources: updatedResources,
          };
        });
      }
    });
    Events.on(Constants.Events.locationChanged, async () => {
      this._toggleLoadingOverlay();
      this.setState(() => ({ isReadyToRender: false }));
      SignalRHubService.closeCommunicationHub();
      await this._getSiteConfig();
      await this._getLocationConfig();
      this._checkOnboardingStatus();
      await this._getSupportInfo();
      await this._getPhoneCountryCode();
      await this._setI18nLanguage();
      await this._getAppointments();
      SignalRHubService.setupCommunicationHub();
      this._toggleLoadingOverlay();

      eBuilder
        .withCategory(eBuilder.Category.Scheduler.bookAppointment)
        .withAction(eBuilder.Action.Scheduler.Click.changeLocation)
        .withLabel(
          eBuilder.Label.alternateLabel,
          this.state.locationConfig.uniqueLocationId
        )
        .post();

      this.setState(() => ({ isReadyToRender: true }));
    });
    Events.on(Constants.Events.appointmentConfirmed, async () => {
      const { currDateTime } = this.state;
      this._toggleLoadingOverlay();
      await this._getAppointments(currDateTime);
      this._toggleLoadingOverlay();
    });
  }

  _toggleLoadingOverlay = () => {
    this.setState((prevState) => ({
      showLoadingOverlay: !prevState.showLoadingOverlay,
    }));
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      appointments,
      appointmentTypes,
      currDateTime,
      dayDuration,
      isPaneCollapsed,
      isReadyToRender,
      locationConfig,
      resources,
      showLoadingOverlay,
      featureConfiguration,
    } = this.state;
    const { countrySettings, locationSettings } = locationConfig;

    return (
      <div className={`cal ${isPaneCollapsed ? 'cal--collapsed' : ''}`}>
        {isReadyToRender && (
          <section className="menu">
            <AdminMenu
              settingIsStellestEnabled={locationSettings.find(
                (e) =>
                  e.displayName ===
                  Constants.LocationSettings.settingIsStellestEnabled
              )}
              calendarActive={true}
            />
          </section>
        )}
        {isReadyToRender && <StellestRegistrationForm />}
        {isReadyToRender && (
          <section
            className={`pane ${isPaneCollapsed ? 'pane--collapsed' : ''}`}
          >
            <CalendarPane
              appointmentTypes={appointmentTypes}
              currDateTime={currDateTime}
              resources={resources}
              onAppointmentTypeChange={this.onAppointmentTypeChange}
              onCurrDateTimeChange={this.onCurrDateTimeChange}
              onResourceChange={this.onResourceChange}
              eventBuilder={eBuilder}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="scheduler">
            <CalendarQueueMobile
              appointments={appointments}
              countrySettings={countrySettings}
              locationSettings={locationSettings}
            />
            <CalendarScheduler
              appointmentTypes={appointmentTypes}
              holidays={this._appliedHolidays}
              appointments={appointments}
              currDateTime={currDateTime}
              dayDuration={dayDuration}
              resources={resources}
              locationConfig={locationConfig}
              onDeleteAppointment={this.onDeleteAppointment}
              onMarkAppointmentAsNoShow={this.onMarkAppointmentAsNoShow}
              onMoveAppointmentToScheduled={this.onMoveAppointmentToScheduled}
              onCurrDateTimeChange={this.onCurrDateTimeChange}
              onUpdateAppointment={this.onUpdateAppointment}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-calendar__footer">
            <CalendarFooter
              supportInfo={this._supportInfo}
              onAppointmentTypeChange={this.onAppointmentTypeChange}
              settingIsStellestEnabled={locationSettings.find(
                (e) =>
                  e.displayName ===
                  Constants.LocationSettings.settingIsStellestEnabled
              )}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="queue">
            <CalendarDayViewer
              onDurationChange={this.onDayViewerDurationChange}
            />
            <CalendarQueue
              appointments={appointments}
              countrySettings={countrySettings}
              locationSettings={locationSettings}
            />
          </section>
        )}
        {isReadyToRender && !featureConfiguration?.EnableAddPlusMenu && (
          <CalendarAddAppointmentDrawer />
        )}
        {isReadyToRender && featureConfiguration?.EnableAddPlusMenu && (
          <AddDrawer />
        )}

        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Calendar);
