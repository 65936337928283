import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import AddDrawer from '../../Components/AddDrawer/AddDrawer';
import AdminMenu from '../../Components/AdminMenu/AdminMenu';
import CalendarAddAppointmentDrawer from '../../Components/CalendarAddAppointmentDrawer/CalendarAddAppointmentDrawer';
import GenericFooter from '../../Components/GenericFooter/GenericFooter';
import Overlay from '../../Components/Overlay/Overlay';
import StellestRegistrationForm from '../../Components/StellestRegistrationForm/StellestRegistrationForm';
import Constants from '../../constants';
import Enums, { NotificationType, RatingValue } from '../../enums';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import AuthService from '../../Services/authService';
import ContentManagementService from '../../Services/contentManagementService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import LocationConfigService from '../../Services/locationConfigService';
import NotificationsService from '../../Services/notificationsService';
import SignalRHubService from '../../Services/signalRHubService';
import SystemMessageService from '../../Services/systemMessageService';
import Session from '../../session';
import Storage from '../../storage';
import Utils from '../../utils';
import './Notifications.scss';

const eBuilder = new EventBuilder();

/**
 * Represents the notifications page where admins can view messages about appointments.
 */
class Notifications extends Component {
  /**
   * Initializes a new instance of the Notifications component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      currAppointmentFeedback: {
        comments: '',
        rating: -1,
        originator: '',
      },
      isReadyToRender: false,
      location: '',
      notifications: [],
      showFeedbackModal: false,
      showLoadingOverlay: false,
      featureConfiguration: {},
    };
    this._maxRating = 5;
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._locationGroup = '';
    this._locationId = Storage.getItem(Constants.currLocIdKey);
    this._notificationsService = new NotificationsService();
    this._locationConfigService = new LocationConfigService();
    this._systemMessageService = new SystemMessageService();
    this._contentManagementService = new ContentManagementService();
    this._featureConfigurationService = new FeatureConfigurationService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    this.setState(() => ({ showLoadingOverlay: true }));

    try {
      const isAuthenticated = await AuthService.authenticateUser();

      if (!isAuthenticated) {
        AuthService.redirectToLogin();
      }
    } catch (error) {
      AuthService.redirectToLogin();
    }

    try {
      const data = {
        locationId: this._locationId,
        offset: this._offset,
        limit: this._limit,
      };

      await this._getLocationConfig();
      this._setDateTimeLocale();
      await this._setI18nLanguage();
      await this._getNotifications(data);
      this._setupEventListeners();
      this._setupSubscriptions();

      const featureConfiguration =
        await this._featureConfigurationService.getFeatureConfiguration(
          process.env.REACT_APP_ENVIRONMENT
        );
      this.setState(() => ({
        featureConfiguration: featureConfiguration,
      }));

      Events.emit(Constants.Events.pageReady);

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));

      SignalRHubService.setupCommunicationHub();
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);
        this.setState(() => ({
          isReadyToRender: true,
          showLoadingOverlay: false,
        }));
      }
    }
  }

  /**
   * Executes when the component unmounts from the DOM.
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this._safeScroll);
    Events.removeAllListeners(Constants.Events.newNotification);
    Events.removeAllListeners(Constants.Events.onLogout);
    Events.removeAllListeners(Constants.Events.locationChanged);
  }

  _getRatingItems = (appointmentFeedback) => {
    const ratingItems = [];

    for (let index = 0; index < appointmentFeedback.rating; ++index) {
      ratingItems.push(
        <li key={index}>
          <StarIcon />
        </li>
      );
    }

    const numFilledItems = ratingItems.length;

    if (numFilledItems < this._maxRating) {
      const numEmptyItems = this._maxRating - numFilledItems;

      for (let index = 0; index < numEmptyItems; ++index) {
        ratingItems.push(
          <li key={numFilledItems + index + 1}>
            <StarOutlineIcon />
          </li>
        );
      }
    }

    return ratingItems;
  };

  _getFormattedNotificationMessage = (notification) => {
    let message = '';

    if (notification) {
      const { t } = this.props;
      const {
        appointmentTypeDisplayName,
        appointmentFeedback,
        notificationType,
      } = notification;

      if (
        notificationType === NotificationType.appointmentBooked ||
        notificationType === NotificationType.appointmentUpdated
      ) {
        if (
          notification.appointmentStatusId === Enums.AppointmentStatus.scheduled
        ) {
          message = `${t('New Appointment')} - ${appointmentTypeDisplayName}`;
        } else if (
          notification.appointmentStatusId === Enums.AppointmentStatus.queued
        ) {
          message = `${t('New Waitlist')} - ${appointmentTypeDisplayName}`;
        } else if (
          notification.appointmentStatusId === Enums.AppointmentStatus.noShow
        ) {
          message = `${t(
            'No Show Appointment'
          )} - ${appointmentTypeDisplayName}`;
        } else if (
          notification.appointmentStatusId === Enums.AppointmentStatus.cancelled
        ) {
          message = `${t(
            'Appointment Cancellation'
          )} - ${appointmentTypeDisplayName}`;
        }
      } else if (
        notificationType === NotificationType.appointmentFeedbackReceived
      ) {
        message =
          appointmentFeedback?.rating <= RatingValue.neutral
            ? t('Poor Review')
            : t('Good Review');
      }
    }

    return message;
  };

  _getLocationConfig = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const locationConfig =
          await this._locationConfigService.getLocationConfig();
        const { countrySettings, locationSettings } = locationConfig;

        this._locationGroup = locationConfig?.locationGroup;
        this._feedbackAllowed =
          countrySettings?.some(
            (cs) =>
              cs.settingName ===
                Constants.CountrySettings
                  .settingIsRequestCustomerFeedbackEnabled &&
              cs.settingValue === true
          ) &&
          locationSettings?.some(
            (ls) =>
              ls.displayName ===
                Constants.LocationSettings
                  .settingIsRequestCustomerFeedbackEnabled &&
              ls.settingValue === true
          );

        const isStellestEnabled = locationSettings.find(
          (e) =>
            e.displayName ===
            Constants.LocationSettings.settingIsStellestEnabled
        );
        Session.setItem(
          Constants.currCountry,
          locationConfig.storeInformation.countryCode
        );
        this.setState(() => ({
          location: locationConfig?.storeInformation?.name,
          config: locationConfig,
          isStellestEnabled: isStellestEnabled,
        }));

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  _getNotifications = async (data) => {
    const notificationData = await this._notificationsService.getNotifications(
      data
    );

    if (notificationData) {
      this.setState((prevState) => {
        const updatedNotifications = notificationData.notifications.map((n) => {
          const dateTime = n.appointmentStartTime
            ? momentLocaleWrapper(n.appointmentStartTime)
                .format(Constants.DateFormats.notificationAppointmentDateFormat)
                .toString()
            : momentLocaleWrapper
                .utc(n.appointmentCreated)
                .local()
                .format(Constants.DateFormats.notificationAppointmentDateFormat)
                .toString();

          const notification = {
            ...n,
            dateTime: dateTime,
          };
          notification.message =
            this._getFormattedNotificationMessage(notification);

          return notification;
        });

        let notifications = updatedNotifications;

        if (
          prevState.notifications.length > 0 &&
          parseInt(prevState.notifications[0].locationId) === this._locationId
        ) {
          notifications = prevState.notifications.concat(updatedNotifications);
        }

        this._offset += this._limit;
        this._total = notificationData.total;

        return {
          notifications: notifications,
        };
      });
    }
  };

  _getNotificationItems = () => {
    const { notifications, config } = this.state;
    const { countrySettings, locationSettings } = config;
    const { t } = this.props;
    const isWhatsAppPhoneLinksEnabled =
      countrySettings.some(
        (s) =>
          s.settingName ===
            Constants.CountrySettings.settingIsWhatsAppB2CEnabled &&
          s.settingValue === true
      ) &&
      locationSettings.some(
        (s) =>
          s.displayName ===
            Constants.LocationSettings.settingIsWhatsAppB2CEnabled &&
          s.settingValue === true
      );
    const notificationItems = notifications.map((notification, key) => {
      let dotClassStatus = '';
      const { appointmentStatusId, isRead, notificationType } = notification;

      if (isRead) {
        dotClassStatus = 'notifications__dot--read';
      }

      if (
        !isRead &&
        (notificationType === NotificationType.appointmentBooked ||
          notificationType === NotificationType.appointmentUpdated)
      ) {
        if (
          appointmentStatusId === Enums.AppointmentStatus.scheduled ||
          appointmentStatusId === Enums.AppointmentStatus.queued
        ) {
          dotClassStatus = 'notifications__dot--unread';
        } else if (appointmentStatusId === Enums.AppointmentStatus.noShow) {
          dotClassStatus = 'notifications__dot--noshow';
        } else if (appointmentStatusId === Enums.AppointmentStatus.cancelled) {
          dotClassStatus = 'notifications__dot--cancel';
        } else {
          dotClassStatus = 'notifications__dot--read';
        }
      } else if (
        !isRead &&
        notificationType === NotificationType.appointmentFeedbackReceived
      ) {
        dotClassStatus = 'notifications__dot--feedback';
      }

      const { appointmentFeedback } = notification;
      if (appointmentFeedback !== null) {
        appointmentFeedback.originator = notification.originator;
      }

      const wasFeedbackSubmitted =
        appointmentFeedback !== null
          ? !!appointmentFeedback.submittedDate &&
            notificationType === NotificationType.appointmentFeedbackReceived
          : false;

      return (
        <tr key={key} onClick={() => this._onNotificationClick(notification)}>
          <td className="notifications__status">
            <span className={`notifications__dot ${dotClassStatus}`}></span>
          </td>
          <td className="notifications__message">{notification.message}</td>
          <td className="notifications__datetime">{notification.dateTime}</td>
          <td className="notifications__author">{notification.originator}</td>
          <td className="notifications__email">
            <a href={`mailto:${notification.customerEmail}`}>
              {notification.customerEmail}
            </a>
          </td>
          {this._feedbackAllowed && (
            <td className="notifications__feedback">
              <div className="notifications__feedback-cell">
                {wasFeedbackSubmitted
                  ? appointmentFeedback.rating <= RatingValue.neutral
                    ? t('Poor Review')
                    : t('Good Review')
                  : ''}
                {wasFeedbackSubmitted && (
                  <button
                    className="notifications__show-feedback"
                    onClick={() => this._onShowFeedback(appointmentFeedback)}
                  >
                    <OpenInNewIcon />
                  </button>
                )}
              </div>
            </td>
          )}
          {isWhatsAppPhoneLinksEnabled && (
            <td className="notifications__phone">
              {' '}
              <a
                href={`${process.env.REACT_APP_WHATSAPP_API}/send?phone=${notification.customerPhone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {notification.customerPhone}
              </a>
            </td>
          )}
          {!isWhatsAppPhoneLinksEnabled && (
            <td className="notifications__phone">
              {notification.customerPhone}
            </td>
          )}
          {Utils.isVoucherAllowed(this._locationGroup) && (
            <td className="notifications__voucher">
              {notification.voucherCode}
            </td>
          )}
          <td className="notifications__resource">
            {notification.resourceDisplayName}
          </td>
        </tr>
      );
    });

    if (!notificationItems || notificationItems.length === 0) {
      notificationItems.push(
        <tr className="notifications__no-notifications" key={0}>
          <td colSpan="7">{t('No notifications')}</td>
        </tr>
      );
    }

    return notificationItems;
  };

  _onCloseFeedbackModal = () => {
    this.setState(() => ({ showFeedbackModal: false }));
  };

  _onNotificationClick = (notification) => {
    if (notification && !notification.isRead) {
      this.setState((prevState) => {
        const updatedNotifications = prevState.notifications.map((n) => {
          if (n.notificationId === notification.notificationId) {
            n.isRead = true;
          }

          return n;
        });

        const hasUnreadNotifications = updatedNotifications.some(
          (n) => !n.isRead
        );
        if (!hasUnreadNotifications) {
          Events.emit(Constants.Events.readAllNotifications);
        }

        // Fire and forget the update.
        const data = {
          isRead: notification.isRead,
          locationId: this._locationId,
          resourceId: notification.resourceId,
          createdDate: notification.createdDate,
          appointmentId: notification.appointmentId,
          notificationId: notification.notificationId,
        };
        this._notificationsService.saveNotification(data);

        return {
          notifications: updatedNotifications,
        };
      });
    }
  };

  _onShowFeedback = (appointmentFeedback) => {
    this.setState(() => ({
      currAppointmentFeedback: appointmentFeedback,
      showFeedbackModal: true,
    }));
  };

  _onWindowScroll = async () => {
    const { notifications } = this.state;

    // If the user hasn't scrolled to the bottom of the window and
    // we haven't retrieved the total number of notifications yet.
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
        document.body.offsetHeight &&
      notifications.length < this._total
    ) {
      this.setState(() => ({ showLoadingOverlay: true }));

      try {
        const data = {
          locationId: this._locationId,
          offset: this._offset,
          limit: this._limit,
        };

        await this._getNotifications(data);
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          console.error(error);
        }
      } finally {
        this.setState(() => ({ showLoadingOverlay: false }));
      }
    }
  };

  _setDateTimeLocale() {
    momentLocaleWrapper.locale(Storage.getItem(Constants.langTag));
  }

  _setI18nLanguage = async () => {
    const { languageTag } = this.state.config.storeInformation;

    Storage.setItem(Constants.langTag, languageTag);

    await this._contentManagementService.loadLocalizations(languageTag);

    return await this.props.i18n.changeLanguage(languageTag);
  };

  _setupEventListeners = () => {
    // Setup window scroll event, so that we can add infinite loading.
    this._safeScroll = Utils.debounce(this._onWindowScroll, 250);

    window.addEventListener('scroll', this._safeScroll);
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.onLogout, () => {
      AuthService.logoutUser();
    });
    Events.on(Constants.Events.newNotification, (notification) => {
      if (notification) {
        notification.message =
          this._getFormattedNotificationMessage(notification);
        notification.dateTime =
          notification.appointmentStatusId === Enums.AppointmentStatus.queued
            ? momentLocaleWrapper
                .utc(notification.appointmentCreated)
                .local()
                .format(Constants.DateFormats.notificationAppointmentDateFormat)
                .toString()
            : momentLocaleWrapper(notification.appointmentStartTime)
                .format(Constants.DateFormats.notificationAppointmentDateFormat)
                .toString();

        this.setState((prevState) => {
          // Create a brand new array by concatenating with an empty one.
          const updatedNotifications = [].concat(prevState.notifications);
          // Then add the new notification to the beginning of the array.
          updatedNotifications.unshift(notification);

          return {
            notifications: updatedNotifications,
          };
        });
      }
    });

    Events.on(Constants.Events.locationChanged, async () => {
      this._locationId = Storage.getItem(Constants.currLocIdKey);

      const data = {
        locationId: this._locationId,
        offset: 0,
        limit: this._limit,
      };
      SignalRHubService.closeCommunicationHub();
      this.setState(() => ({ showLoadingOverlay: true, notifications: [] }));
      this._systemMessageService.resetSystemMessageCount();

      await this._getLocationConfig();
      this._setDateTimeLocale();
      await this._setI18nLanguage();
      await this._getNotifications(data);
      this._systemMessageService.checkForMessages();

      SignalRHubService.setupCommunicationHub();

      eBuilder
        .withCategory(eBuilder.Category.Scheduler.bookAppointment)
        .withAction(eBuilder.Action.Scheduler.Click.changeLocation)
        .withLabel(
          eBuilder.Label.alternateLabel,
          this.state.config.uniqueLocationId
        )
        .post();

      this.setState(() => ({ showLoadingOverlay: false }));
    });
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      currAppointmentFeedback,
      isReadyToRender,
      location,
      showFeedbackModal,
      showLoadingOverlay,
      isStellestEnabled,
      featureConfiguration,
    } = this.state;
    const { t } = this.props;
    const { comments, originator } = currAppointmentFeedback;

    return (
      <div className="notifications">
        {isReadyToRender && (
          <section className="menu">
            <AdminMenu
              location={location}
              showAddAppointment={false}
              showHamburgerMenu={false}
              onToggleCalendarPane={() => {}}
              notificationsActive={true}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="submenu">
            <h2 className="submenu__title">{t('Notifications')}</h2>
            <div className="submenu__cont">
              <ul className="submenu__legend">
                <li>
                  <span className="notifications__dot notifications__dot--unread"></span>
                  <span className="notifications__dot-label">{`${t(
                    'New Appointment or Waitlist'
                  )}`}</span>
                </li>
                <li>
                  <span className="notifications__dot notifications__dot--read"></span>
                  <span className="notifications__dot-label">{`${t(
                    'Read Appointment or Waitlist'
                  )}`}</span>
                </li>
                <li>
                  <span className="notifications__dot notifications__dot--noshow"></span>
                  <span className="notifications__dot-label">{`${t(
                    'No Show Appointment'
                  )}`}</span>
                </li>
                <li>
                  <span className="notifications__dot notifications__dot--cancel"></span>
                  <span className="notifications__dot-label">{`${t(
                    'Appointment Cancellation'
                  )}`}</span>
                </li>
                {this._feedbackAllowed && (
                  <li>
                    <span className="notifications__dot notifications__dot--feedback"></span>
                    <span className="notifications__dot-label">{`${t(
                      'RATING / REVIEW'
                    )}`}</span>
                  </li>
                )}
              </ul>
              <p className="submenu__autodel">
                {ReactHtmlParser(
                  t(
                    'All notifications older than 30 days will be automatically deleted'
                  )
                )}
              </p>
            </div>
          </section>
        )}
        {isReadyToRender && (
          <section className="page">
            <table className="page__messages">
              <thead>
                <tr>
                  <th>{t('Read/Unread')}</th>
                  <th>{t('Message')}</th>
                  <th>{`${t('Date')} / ${t('Time')}`}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Email')}</th>
                  {this._feedbackAllowed && <th>{t('Review Feedback')}</th>}
                  <th>{t('Phone')}</th>
                  {Utils.isVoucherAllowed(this._locationGroup) && (
                    <th>{t('Voucher Code')}</th>
                  )}
                  <th>{t('Resource Name')}</th>
                </tr>
              </thead>
              <tbody>{this._getNotificationItems()}</tbody>
            </table>
          </section>
        )}
        <Dialog open={showFeedbackModal}>
          <DialogTitle disableTypography>
            <div className="notifications-modal__header">
              <h3 className="notifications-modal__title">
                {t('Review Feedback')}
              </h3>
              <ul className="notifications-modal__rating">
                {this._getRatingItems(currAppointmentFeedback)}
              </ul>
            </div>
            <button
              className="notifications-modal__dismiss"
              onClick={this._onCloseFeedbackModal}
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            <div className="notifications-modal__content">
              <p className="notifications-modal__consumer">
                {t('Consumer')}:&nbsp;{originator}
              </p>
              <p className="notifications-modal__comments">{comments}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="notifications-modal__footer">
              <button
                className="notifications-modal__close"
                onClick={this._onCloseFeedbackModal}
              >
                {t('Close')}
              </button>
            </div>
          </DialogActions>
        </Dialog>
        {featureConfiguration?.EnableAddPlusMenu && <AddDrawer />}
        {!featureConfiguration?.EnableAddPlusMenu && (
          <CalendarAddAppointmentDrawer />
        )}
        <StellestRegistrationForm />
        <section className="notifications__footer">
          <GenericFooter settingIsStellestEnabled={isStellestEnabled} />
        </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Notifications);
