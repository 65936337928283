import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AddDrawer from '../../Components/AddDrawer/AddDrawer';
import AdminMenu from '../../Components/AdminMenu/AdminMenu';
import AppointmentTypes from '../../Components/AppointmentTypes/AppointmentTypes';
import CalendarAddAppointmentDrawer from '../../Components/CalendarAddAppointmentDrawer/CalendarAddAppointmentDrawer';
import CustomerData from '../../Components/CustomerData/CustomerData';
import CustomerFeedback from '../../Components/CustomerFeedback/CustomerFeedback';
import Customers from '../../Components/Customers/Customers';
import CustomerStoreFront from '../../Components/CustomerStoreFront/CustomerStoreFront';
import GenericFooter from '../../Components/GenericFooter/GenericFooter';
import LocationSettings from '../../Components/LocationSettings/LocationSettings';
import Overlay from '../../Components/Overlay/Overlay';
import QRCodePoster from '../../Components/QRCodePoster/QRCodePoster';
import Resources from '../../Components/Resources/Resources';
import StellestRegistrationForm from '../../Components/StellestRegistrationForm/StellestRegistrationForm';
import StoreHolidays from '../../Components/StoreHolidays/StoreHolidays';
import StoreHours from '../../Components/StoreHours/StoreHours';
import StoreInformation from '../../Components/StoreInformation/StoreInformation';
import Constants from '../../constants';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import AuthService from '../../Services/authService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import LocationConfigService from '../../Services/locationConfigService';
import NotificationService from '../../Services/notificationsService';
import SettingsService from '../../Services/settingsService';
import SignalRHubService from '../../Services/signalRHubService';
import SupportInfoService from '../../Services/supportInfoService';
import Storage from '../../storage';
import Utils from '../../utils';
import './Settings.scss';

const ListSubheader = withStyles({
  root: {
    fontSize: '0.9rem',
    paddingLeft: '2.2em',
    color: '#308bb0',
  },
})(MuiListSubheader);

const ListItemText = withStyles({
  root: {
    fontSize: '0.9rem',
    paddingLeft: '2em',
  },
})(MuiListItemText);

class SettingsItemType {
  static storeInfo = 0;

  static storeHours = 1;

  static appointmentTypes = 2;

  static resources = 3;

  static qrCodeStorePoster = 4;

  static storefrontUrl = 5;

  static exportCustomerData = 6;

  static notifications = 7;

  static storeHolidays = 8;

  static customerFeedback = 9;

  static viewCustomerData = 12;
}

/**
 * Represents the admin settings.
 */
class Settings extends Component {
  /**
   * Initializes a new instance of the Settings component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      currSettingsItemType: SettingsItemType.storeInfo,
      errorModalMessage: '',
      isReadyToRender: false,
      isStoreInformationDirty: false,
      locationId: 0,
      showErrorModal: false,
      showLoadingOverlay: false,
      supportInfo: {
        email: '',
        phone: '',
      },
      uniqueLocationUrl: '',
      featureConfiguration: {},
    };
    this._hasPlaceId = false;
    this._holidays = [];
    this._resources = [];
    this._storeHours = [];
    this._appointmentTypes = [];
    this._storeInformation = {};
    this._locationSettings = [];
    this._settingsService = new SettingsService();
    this._supportInfoService = new SupportInfoService();
    this._notificationService = new NotificationService();
    this._locationConfigService = new LocationConfigService();
    this._appointmentInterval = 0;
    this._googleReviewInfo = {};
    this._featureConfigurationService = new FeatureConfigurationService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this.setState(() => ({ showLoadingOverlay: true }));

      try {
        const isAuthenticated = await AuthService.authenticateUser();

        if (!isAuthenticated) {
          AuthService.redirectToLogin();
          return;
        }
      } catch {
        AuthService.redirectToLogin();
        return;
      }

      this._setDateTimeLocale();
      await this._setI18nLanguage();
      await this._getLocationConfig();
      await this._getLocationHolidays();
      await this._getSupportInfo();
      this._setupSubscriptions();
      SignalRHubService.setupCommunicationHub();

      const featureConfiguration = await this._featureConfigurationService.getFeatureConfiguration(
        process.env.REACT_APP_ENVIRONMENT
      );
      this.setState(() => ({
        featureConfiguration: featureConfiguration,
      }));

      Events.emit(Constants.Events.pageReady);

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);

        this.setState(() => ({
          isReadyToRender: false,
          showLoadingOverlay: false,
        }));
      }
    }
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.onLogout);
    Events.removeAllListeners(Constants.Events.locationConfigUpdate);
    Events.removeAllListeners(Constants.Events.locationChanged);
    SignalRHubService.closeCommunicationHub();
  }

  _getCurrSettingsItemComponent = () => {
    let currSettingsItemComponent = null;
    const { currSettingsItemType, locationId, uniqueLocationUrl } = this.state;

    switch (currSettingsItemType) {
      case SettingsItemType.storeInfo: {
        currSettingsItemComponent = (
          <StoreInformation
            countrySettings={this._countrySettings}
            locationSettings={this._locationSettings}
            storeInformation={this._storeInformation}
            onLocationSettingsChange={this.onLocationSettingsChange}
            onStoreInformationChange={this.onStoreInformationChange}
            onGoogleReviewInfoChange={this.onGoogleReviewInfoChange}
            googleReviewInfo={this._googleReviewInfo}
          />
        );
        break;
      }
      case SettingsItemType.storeHours: {
        currSettingsItemComponent = (
          <StoreHours
            isOnboarded={false}
            storeHours={this._storeHours}
            showSave={true}
            onStoreHoursChange={this.onStoreHoursChange}
            appointmentInterval={this._appointmentInterval}
            onAppointmentIntervalChange={this.onAppointmentIntervalChange}
          />
        );
        break;
      }
      case SettingsItemType.storeHolidays: {
        currSettingsItemComponent = (
          <StoreHolidays
            holidays={this._holidays}
            locationId={locationId}
            onHolidaysChange={this.onHolidaysChange}
          />
        );
        break;
      }
      case SettingsItemType.appointmentTypes: {
        currSettingsItemComponent = (
          <AppointmentTypes
            isOnboarded={false}
            locationSettings={this._locationSettings}
            appointmentTypes={this._appointmentTypes}
            onAppointmentTypesChange={this.onAppointmentTypesChange}
          />
        );
        break;
      }
      case SettingsItemType.resources: {
        currSettingsItemComponent = (
          <Resources
            locationId={locationId}
            resources={this._resources}
            storeHours={this._storeHours}
            onResourcesChange={this.onResourcesChange}
          />
        );
        break;
      }
      case SettingsItemType.notifications: {
        currSettingsItemComponent = (
          <LocationSettings
            countrySettings={this._countrySettings}
            hasSmsPhone={!!this._storeInformation.smsPhone}
            locationSettings={this._locationSettings}
            onLocationSettingsChange={this.onLocationSettingsChange}
          />
        );
        break;
      }
      case SettingsItemType.customerFeedback: {
        currSettingsItemComponent = (
          <CustomerFeedback
            countrySettings={this._countrySettings}
            locationSettings={this._locationSettings}
            onLocationSettingsChange={this.onLocationSettingsChange}
          />
        );
        break;
      }
      case SettingsItemType.qrCodeStorePoster: {
        currSettingsItemComponent = (
          <QRCodePoster
            storeName={this._storeInformation.name}
            uniqueLocationUrl={uniqueLocationUrl}
          />
        );
        break;
      }
      case SettingsItemType.storefrontUrl: {
        currSettingsItemComponent = (
          <CustomerStoreFront
            countrySettings={this._countrySettings}
            hasPlaceId={this._hasPlaceId}
            uniqueLocationUrl={uniqueLocationUrl}
          />
        );
        break;
      }
      case SettingsItemType.exportCustomerData:
        currSettingsItemComponent = <CustomerData />;
        break;
      case SettingsItemType.viewCustomerData:
        currSettingsItemComponent = <Customers />;
        break;
      default: {
        currSettingsItemComponent = (
          <StoreInformation
            countrySettings={this._countrySettings}
            locationSettings={this._locationSettings}
            storeInformation={this._storeInformation}
            onLocationSettingsChange={this.onLocationSettingsChange}
            onStoreInformationChange={this.onStoreInformationChange}
            onGoogleReviewInfoChange={this.onGoogleReviewInfoChange}
            googleReviewInfo={this._googleReviewInfo}
          />
        );
        break;
      }
    }

    return currSettingsItemComponent;
  };

  _getLocationConfig = async () => {
    try {
      const locationConfig = await this._locationConfigService.getLocationConfig();
      const {
        appointmentTypes,
        countrySettings,
        locationSettings,
        resources,
        storeHours,
        placeId,
        storeInformation,
        uniqueLocationURL,
        appointmentInterval,
        googleReviewInfo,
      } = locationConfig;

      Utils.formatAppointmentTypes(appointmentTypes);
      Utils.formatResources(resources);

      // Sort the resources based on their sort order.
      const sortedResources = resources.sort((res1, res2) =>
        res1.sortOrder > res2.sortOrder ? 1 : -1
      );

      if (googleReviewInfo) {
        this._googleReviewInfo = cloneDeep(googleReviewInfo);
      } else {
        const defaultGoogleReviewInfo = {
          placeId: '',
          rating: 0,
          numberOfUserRatings: 0,
          viewReviewUrl: 'https://search.google.com/local/reviews?placeid=',
        };
        this._googleReviewInfo = cloneDeep(defaultGoogleReviewInfo);
      }

      this._hasPlaceId = !!placeId;
      this._storeHours = cloneDeep(storeHours);
      this._resources = cloneDeep(sortedResources);
      this._countrySettings = cloneDeep(countrySettings);
      this._locationSettings = cloneDeep(locationSettings);
      this._appointmentTypes = cloneDeep(appointmentTypes);
      this._storeInformation = cloneDeep(storeInformation);
      this._appointmentInterval = appointmentInterval;

      const isStellestEnabled = locationConfig.locationSettings.find(
        (e) => e.displayName === Constants.LocationSettings.settingIsStellestEnabled
      );

      this.setState(() => ({
        locationId: locationConfig.locationId,
        uniqueLocationUrl: uniqueLocationURL,
        isStellestEnabled: isStellestEnabled,
        appointmentInterval: appointmentInterval,
      }));
    } catch (error) {
      this._toggleErrorModal(
        error,
        true,
        this.props.t('There was an unexpected issue with retrieving the settings')
      );
    }
  };

  _getLocationHolidays = async () => {
    try {
      const holidays = await this._settingsService.getLocationHolidays({
        locationId: this.state.locationId,
      });

      // Loop through and assign a temp id that allows us to track adding/removing/editing holidays.
      for (let index = 0; index < holidays.length; ++index) {
        const holiday = holidays[index];
        holiday.id = index;
        holiday.disabled = false;
        holiday.startDate = momentLocaleWrapper(
          holiday.startDate,
          '(YYYY, MM, DD, HH, mm)'
        ).toDate();
        holiday.endDate = momentLocaleWrapper(holiday.endDate, '(YYYY, MM, DD, HH, mm)').toDate();
      }

      this._holidays = cloneDeep(holidays);
    } catch (error) {
      this._toggleErrorModal(
        error,
        true,
        this.props.t('There was an unexpected issue with retrieving the settings')
      );
    }
  };

  _getSupportInfo = async () => {
    try {
      const supportInfo = await this._supportInfoService.getSupportInfo(
        this._storeInformation.countryCode
      );

      this.setState(() => ({ supportInfo: cloneDeep(supportInfo) }));
    } catch (error) {
      this._toggleErrorModal(
        error,
        true,
        this.props.t('There was an unexpected issue with retrieving the settings')
      );
    }
  };

  /**
   * Executes when the appointment types change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Array} appointmentTypes The appointment types.
   */
  onAppointmentTypesChange = (appointmentTypes) => {
    this._appointmentTypes = cloneDeep(appointmentTypes);
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  /**
   * Executes when the holidays change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Array} holidays The holidays.
   */
  onHolidaysChange = (holidays) => {
    this._holidays = cloneDeep(holidays);
  };

  /**
   * Executes when the location settings change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Array} locationSettigs The location settings.
   */
  onLocationSettingsChange = (locationSettigs) => {
    this._locationSettings = cloneDeep(locationSettigs);
  };

  /**
   * Executes when the resources change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Array} resources The resources.
   */
  onResourcesChange = (resources) => {
    this._resources = cloneDeep(resources);
  };

  _onSettingsItemClick = (settingsItemType) => {
    if (this.state.currSettingsItemType !== settingsItemType) {
      this.setState(() => ({ currSettingsItemType: settingsItemType }));
    }
  };

  /**
   * Executes when the store hours change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Array} storeHours The store hours.
   */
  onStoreHoursChange = (storeHours) => {
    this._storeHours = cloneDeep(storeHours);
  };

  /**
   * Executes when the appointment interval change. This is to keep each section in synchronization if the user switches between sections.
   * @param {Number} appointmentInterval The appointment interval.
   */
  onAppointmentIntervalChange = (appointmentInterval) => {
    this._appointmentInterval = appointmentInterval;
  };

  /**
   * Executes when the store information changes. This is to keep each section in synchronization if the user switches between sections.
   * @param {Object} storeInformation The store information.
   */
  onStoreInformationChange = (storeInformation) => {
    this._storeInformation = cloneDeep(storeInformation);
  };

  /**
   * Executes when the Google Review Info changes. This is to keep each section in synchronization if the user switches between sections.
   * @param {Object} googleReviewInfo The googleReviewInfo.
   */
  onGoogleReviewInfoChange = (googleReviewInfo) => {
    this._googleReviewInfo = cloneDeep(googleReviewInfo);
  };

  _onViewStorefrontUrl = (url) => {
    const eBuilder = new EventBuilder();
    eBuilder
      .withCategory(eBuilder.Category.settings)
      .withAction(eBuilder.Action.Settings.Click.viewConsumerStorefront)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    window.open(url, '_blank');
  };

  _setDateTimeLocale() {
    momentLocaleWrapper.locale(Storage.getItem(Constants.langTag));
  }

  _setI18nLanguage = async () => {
    return this.props.i18n.changeLanguage(Storage.getItem(Constants.langTag));
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.onLogout, () => {
      AuthService.logoutUser();
    });

    Events.on(Constants.Events.locationConfigUpdate, async (locationConfig) => {
      await this._getLocationConfig();
      await this._getLocationHolidays();
    });

    Events.on(Constants.Events.locationChanged, async () => {
      this.setState(() => ({
        isReadyToRender: false,
        showLoadingOverlay: true,
      }));
      SignalRHubService.closeCommunicationHub();
      this._setDateTimeLocale();
      await this._setI18nLanguage();
      await this._getLocationConfig();
      await this._getLocationHolidays();
      await this._getSupportInfo();
      SignalRHubService.setupCommunicationHub();
      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
        currSettingsItemType: SettingsItemType.storeInfo,
      }));
    });
  };

  _toggleErrorModal = (error, show, message) => {
    if (
      (error && !error.response) ||
      (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);

      this.setState(() => ({
        showErrorModal: show,
        errorModalMessage: message,
      }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      currSettingsItemType,
      errorModalMessage,
      isReadyToRender,
      showErrorModal,
      showLoadingOverlay,
      supportInfo,
      isStellestEnabled,
      featureConfiguration,
    } = this.state;
    const { email } = supportInfo;
    const { t } = this.props;
    const currSettingsItemComponent = this._getCurrSettingsItemComponent();

    return (
      <div className="admin-settings">
        {isReadyToRender && (
          <section className="admin-settings__menu">
            <AdminMenu
              settingIsStellestEnabled={this._locationSettings.find(
                (e) => e.displayName === Constants.LocationSettings.settingIsStellestEnabled
              )}
              location={this._storeInformation.name}
              showAddAppointment={false}
              showHamburgerMenu={false}
              onToggleCalendarPane={() => {}}
              settingsActive={true}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-settings__header">
            <h1 className="admin-settings__title">{t('Settings')}</h1>
            <h5 className="admin-settings__support">
              {`${t('Have Support Questions?')}`} <a href={`mailto:${email}`}>{`${email}`}</a>
            </h5>
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-settings__nav">
            <List
              component="nav"
              aria-labelledby="nested-list-subheader__configuration"
              subheader={
                <ListSubheader
                  component="div"
                  disableSticky={true}
                  id="nested-list-subheader__configuration"
                >
                  {t('Configuration')}
                </ListSubheader>
              }
            >
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.storeInfo}
                onClick={() => this._onSettingsItemClick(SettingsItemType.storeInfo)}
              >
                <ListItemText primary={t('Location Information')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.storeHours}
                onClick={() => this._onSettingsItemClick(SettingsItemType.storeHours)}
              >
                <ListItemText primary={t('Hours of Operation')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.storeHolidays}
                onClick={() => this._onSettingsItemClick(SettingsItemType.storeHolidays)}
              >
                <ListItemText primary={t('Holidays')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.appointmentTypes}
                onClick={() => this._onSettingsItemClick(SettingsItemType.appointmentTypes)}
              >
                <ListItemText primary={t('Service Types')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.resources}
                onClick={() => this._onSettingsItemClick(SettingsItemType.resources)}
              >
                <ListItemText primary={t('Booking Lanes Setup')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.notifications}
                onClick={() => this._onSettingsItemClick(SettingsItemType.notifications)}
              >
                <ListItemText primary={t('Notifications')} />
              </ListItem>
            </List>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader__customer"
              subheader={
                <ListSubheader
                  component="div"
                  disableSticky={true}
                  id="nested-list-subheader__customer"
                >
                  {t('Customer')}
                </ListSubheader>
              }
            >
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.customerFeedback}
                onClick={() => this._onSettingsItemClick(SettingsItemType.customerFeedback)}
              >
                <ListItemText primary={t('Manage Ratings/Reviews')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.qrCodeStorePoster}
                onClick={() => this._onSettingsItemClick(SettingsItemType.qrCodeStorePoster)}
              >
                <ListItemText primary={t('QR Code Store Poster')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.storefrontUrl}
                onClick={() => this._onSettingsItemClick(SettingsItemType.storefrontUrl)}
              >
                <ListItemText primary={t('View Storefront')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.exportCustomerData}
                onClick={() => this._onSettingsItemClick(SettingsItemType.exportCustomerData)}
              >
                <ListItemText primary={t('Export Data')} />
              </ListItem>
              <ListItem
                button
                selected={currSettingsItemType === SettingsItemType.viewCustomerData}
                onClick={() => this._onSettingsItemClick(SettingsItemType.viewCustomerData)}
              >
                <ListItemText primary={t('Manage Data')} />
              </ListItem>
            </List>
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-settings__content">{currSettingsItemComponent}</section>
        )}
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button className="admin-settings__modal-btn" onClick={this._onCloseErrorModal}>
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
        {featureConfiguration?.EnableAddPlusMenu && <AddDrawer />}
        {!featureConfiguration?.EnableAddPlusMenu && <CalendarAddAppointmentDrawer />}
        <StellestRegistrationForm />
        <section className="footer">
          <GenericFooter settingIsStellestEnabled={isStellestEnabled} />
        </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Settings);
