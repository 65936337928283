import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AddDrawer from '../../Components/AddDrawer/AddDrawer';
import AdminMenu from '../../Components/AdminMenu/AdminMenu';
import CalendarAddAppointmentDrawer from '../../Components/CalendarAddAppointmentDrawer/CalendarAddAppointmentDrawer';
import GenericFooter from '../../Components/GenericFooter/GenericFooter';
import StellestRegistrationForm from '../../Components/StellestRegistrationForm/StellestRegistrationForm';
import TwoWayHeader from '../../Components/TwoWayHeader/TwoWayHeader';
import TwoWayList from '../../Components/TwoWayList/TwoWayList';
import TwoWayModal from '../../Components/TwoWayModal/TwoWayModal';
import Constants from '../../constants';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import Logger from '../../logger';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import AuthService from '../../Services/authService';
import ContentManagementService from '../../Services/contentManagementService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import LocationConfigService from '../../Services/locationConfigService';
import SignalRHubService from '../../Services/signalRHubService';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import Storage from '../../storage';
import './TwoWayMessagePage.scss';

class TwoWayMessagePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingConversations: true,
      conversations: [],
      conversationContinuationToken: null,
      conversation: null,
      showLocationErrorModal: false,
      showErrorModal: false,
      errorModalMessage: null,
      errorModalClick: null,
      isReadyToRender: false,
      newMessageReceived: null,

      conversationStatusCounts: {
        unread: 0,
        unanswered: 0,
        flagged: 0,
      },
      currentFilter: -1,

      locationConfig: null,
      isWhatsAppNotificationsEnabled: false,
      defaultMessagingPref: Enums.PreferredPlatform.smsText,
      phoneCountryCode: null,
      modalContent: {
        open: false,
        heading: '',
        body: '',
        spinner: false,
        buttons: [],
      },
      templateList: [],
      featureConfiguration: {},
    };

    this.contentManagementService = new ContentManagementService();
    this.locationConfigService = new LocationConfigService();
    this.twoWayConversationService = new TwoWayConversationService();
    this._featureConfigurationService = new FeatureConfigurationService();

    this.eBuilder = new EventBuilder();
  }

  async componentDidMount() {
    try {
      const isAuthenticated = await AuthService.authenticateUser();

      if (!isAuthenticated) {
        AuthService.redirectToLogin();
      }
    } catch (error) {
      AuthService.redirectToLogin();
    }
    const featureConfiguration =
      await this._featureConfigurationService.getFeatureConfiguration(
        process.env.REACT_APP_ENVIRONMENT
      );
    this.setState(() => ({
      featureConfiguration: featureConfiguration,
    }));

    await this.getLocationConfig();

    this.setState(() => ({ isReadyToRender: true }));

    this.setupSubscriptions();
    try {
      this.twoWayConversationService.updateLocationChatNotificiation(false);
    } catch (error) {
      Logger.error(error);
    }
  }

  componentWillUnmount() {
    this.unsubscribeSubscriptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    const conversationKey = pathname.split('/')[3];
    if (
      conversationKey &&
      this.state.conversations.length > 0 &&
      this.state.conversation === null
    ) {
      var iStateConversation = this.state.conversations.filter(
        (c) => c.conversationKey === conversationKey
      )[0];
      if (iStateConversation) {
        this.conversationClick(iStateConversation);
      }
    }
  }

  openModalContent = (heading, body, buttons, spinner) => {
    this.setState(() => ({
      open: true,
      heading,
      body,
      spinner: spinner || false,
      buttons,
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      open: false,
      heading: '',
      body: '',
      spinner: false,
      buttons: [],
    }));
  };
  setupSubscriptions = () => {
    SignalRHubService.setupCommunicationHub();
    Events.on(Constants.Events.onLogout, () => {
      AuthService.logoutUser();
    });
    Events.on(Constants.Events.locationConfigUpdate, (locationConfig) => {
      this.onLocationConfigChanged(locationConfig);
    });
    Events.on(Constants.Events.locationChanged, () => {
      SignalRHubService.closeCommunicationHub();
      SignalRHubService.setupCommunicationHub();
      this.getLocationConfig();
    });
    Events.on(Constants.Events.chatConversationUpdated, (conversation) => {
      Logger.log('conversation updated event received', conversation);
      this.setState((prevState) => {
        var iStaleConversation = prevState.conversations.findIndex(
          (c) => c.conversationKey === conversation.conversationKey
        );

        let activeConversation = prevState.conversation;
        if (iStaleConversation >= 0) {
          let staleConversation = prevState.conversations[iStaleConversation];
          if (staleConversation === activeConversation) {
            activeConversation = conversation;
          }
        }

        return {
          conversation: activeConversation,
          conversations: this.concatAndSortConversations(
            prevState.conversations,
            [conversation]
          ),
        };
      }, this.getConversationStatusCounts);
    });
    Events.on(Constants.Events.chatNotificationUpdated, (chatNotification) => {
      if (chatNotification.hasNotifications) {
        try {
          this.twoWayConversationService.updateLocationChatNotificiation(false);
        } catch (error) {
          Logger.error(error);
        }
      }
    });
  };

  unsubscribeSubscriptions = () => {
    Events.removeListener(Constants.Events.onLogout);
    Events.removeListener(Constants.Events.locationConfigUpdate);
    Events.removeListener(Constants.Events.locationChanged);
    Events.removeListener(Constants.Events.onToggleAddAppointment);
    Events.removeListener(Constants.Events.chatConversationUpdated);
  };

  isChatEnabled = (locationConfig) => {
    const isCountryChatEnabled = locationConfig?.countrySettings?.some(
      (cs) =>
        cs.settingName === Constants.CountrySettings.settingIsChatEnabled &&
        cs.settingValue === true
    );
    const isLocationChatEnabled = locationConfig?.locationSettings?.some(
      (cs) =>
        cs.displayName === Constants.LocationSettings.settingIsChatEnabled &&
        cs.settingValue === true
    );
    return isCountryChatEnabled || isLocationChatEnabled;
  };

  onLocationConfigChanged = (newLocationConfig) => {
    const { locationConfig } = this.state;
    if (
      newLocationConfig &&
      locationConfig &&
      newLocationConfig.locationId === locationConfig.locationId &&
      !this.isChatEnabled(newLocationConfig)
    ) {
      this.getLocationConfig();
    }
  };

  getLocationConfig = async () => {
    const { t } = this.props;
    const {featureConfiguration} = this.state;
    try {
      this.setState(() => ({
        loadingConversations: true,
        conversation: null,
        conversations: [],
      }));
      const locationConfig =
        await this.locationConfigService.getLocationConfig();
      if (locationConfig && this.isChatEnabled(locationConfig)) {
        this.props.history.push('/chat/');
        Logger.log(locationConfig);
        const { languageTag } = locationConfig.storeInformation;
        Storage.setItem(Constants.langTag, languageTag);
        momentLocaleWrapper.locale(languageTag);
        await this.props.i18n.changeLanguage(languageTag);

        const isWhatsAppNotificationsEnabled =
          locationConfig.countrySettings?.some(
            (cs) =>
              cs.settingName ===
                Constants.CountrySettings
                  .settingIsWhatsAppNotificationsEnabled &&
              cs.settingValue === true
          );
        let defaultMessagingPref = locationConfig.countrySettings?.find(
          (cs) =>
            cs.settingName ===
            Constants.CountrySettings.settingDefaultMessagingPlatform
        )?.settingValue;
        if (isWhatsAppNotificationsEnabled) {
          defaultMessagingPref =
            defaultMessagingPref || Enums.PreferredPlatform.whatsApp;
        } else {
          defaultMessagingPref = Enums.PreferredPlatform.smsText;
        }

        const isSmsOneWay =
          locationConfig.countrySettings?.some(
            (cs) =>
              cs.settingName === Constants.CountrySettings.settingIsSmsOneWay &&
              cs.settingValue === true
          ) || false;

        this.getConversationStatusCounts();
        const phoneCountryCode =
          await this.contentManagementService.getPhoneCountryCode(
            locationConfig.storeInformation.countryCode
          );
        let templateList =
          await this.twoWayConversationService.getTemplateList(
            `{${t('Customer First Name')}}`
          );
        
        if (!featureConfiguration.CustomerQuoteEnabled ) {
          templateList = templateList.filter((item) => item.chatTemplateType !== Constants.TwoWayMessages.messageTemplates.customerQuote);
        }

        const isStellestEnabled = locationConfig.locationSettings.find(
          (e) =>
            e.displayName ===
            Constants.LocationSettings.settingIsStellestEnabled
        );

        this.setState(
          () => ({
            locationConfig: cloneDeep(locationConfig),
            isSmsOneWay: isSmsOneWay,
            isWhatsAppNotificationsEnabled: isWhatsAppNotificationsEnabled,
            defaultMessagingPref: defaultMessagingPref,
            phoneCountryCode: phoneCountryCode,
            templateList,
            isStellestEnabled: isStellestEnabled,
          }),
          this.getConversations
        );
      } else {
        this.setState(() => ({
          errorModalClick: this.loadPreviousLocation,
          showLocationErrorModal: true,
          loadingConversations: false,
        }));
        this.openErrorModal(
          'This location is not enabled for Chat 2 way messaging.',
          t('This location is not enabled for Chat 2 way messaging.')
        );
      }
    } catch (error) {
      Logger.error(error);
      this.openErrorModal(
        error,
        t('There was an unexpected issue with retrieving the settings')
      );
    }
  };

  loadPreviousLocation = () => {
    this.setState(() => ({
      showLocationErrorModal: false,
      errorModalClick: null,
    }));
    if (this.state.locationConfig?.locationId) {
      Events.emit(
        Constants.Events.locationReset,
        this.state.locationConfig.locationId
      );
    } else {
      window.location = Constants.Routes.schedule;
    }
  };

  getConversations = async () => {
    try {
      const data = await this.twoWayConversationService.getConversations(
        this.state.currentFilter,
        null
      );
      if (data) {
        Logger.log('getConversations', data);
        this.setState(() => ({
          conversations: data.conversations,
          conversationContinuationToken: data.continuationToken,
          loadingConversations: false,
        }));
      }
    } catch (error) {
      Logger.error(error);
      this.setState(() => ({ loadingConversations: false }));
      this.openErrorModal(
        error,
        this.props.t(
          'There was an unexpected issue with retrieving the settings'
        )
      );
    }
  };

  getConversationStatusCounts = async () => {
    try {
      const data =
        await this.twoWayConversationService.getConversationStatusCounts();
      if (data) {
        Logger.log('getConversations', data);
        this.setState(() => ({
          conversationStatusCounts: data,
        }));
      }
    } catch (error) {
      Logger.error(error);
      this.setState(() => ({ loadingConversations: false }));
      this.openErrorModal(
        error,
        this.props.t(
          'There was an unexpected issue with retrieving the settings'
        )
      );
    }
  };

  filterList = (viewStatus) => {
    this.setState(
      () => ({
        currentFilter: viewStatus,
        continuationToken: null,
        LoadingConversations: true,
      }),
      this.getConversations
    );
  };

  closeErrorModal = () => {
    const { errorModalClick } = this.state;
    if (errorModalClick) {
      errorModalClick();
    }
    this.setState(() => ({
      errorModalClick: null,
      showErrorModal: false,
    }));
  };

  conversationOptOut = (patientKey) => {
    this.setState((prevState) => {
      const { conversations } = prevState;
      conversations.forEach((conversation) => {
        if (conversation.patientKey === patientKey) {
          conversation.isOptedOut = true;
        }
      });
      return {
        conversations: conversations,
      };
    });
  };

  concatAndSortConversations(previousConversations, newConversations) {
    newConversations.forEach((conversation) => {
      var iStaleConversation = previousConversations.findIndex(
        (c) => c.conversationKey === conversation.conversationKey
      );
      if (iStaleConversation >= 0) {
        //Remove stale conversation
        previousConversations.splice(iStaleConversation, 1);
      }
    });
    //Remove deleted conversations
    newConversations =
      newConversations.filter(
        (c) =>
          c.conversationStatus !== Constants.TwoWayMessages.viewStatus.trash
      ) ?? [];
    previousConversations =
      previousConversations.filter(
        (c) =>
          c.conversationStatus !== Constants.TwoWayMessages.viewStatus.trash
      ) ?? [];
    return previousConversations.concat(newConversations).sort((a, b) => {
      return new Date(b.lastTransmission) - new Date(a.lastTransmission);
    });
  }

  loadMoreToList = async () => {
    if (this.state.conversationContinuationToken) {
      try {
        const data = await this.twoWayConversationService.getConversations(
          this.state.currentFilter,
          this.state.conversationContinuationToken
        );
        if (data) {
          Logger.log('getConversations', data);
          this.setState((prevState) => {
            return {
              conversations: this.concatAndSortConversations(
                prevState.conversations,
                data.conversations
              ),
              conversationContinuationToken: data.continuationToken,
            };
          });
        }
      } catch (error) {
        Logger.error(error);
        this.setState(() => ({ loadingConversations: false }));
        this.openErrorModal(
          error,
          this.props.t(
            'There was an unexpected issue with downloading the customer data.'
          )
        );
      }
    }
  };

  refreshList = () => {
    //TODO: Refreshes conversation after sending a message, will be altered or removed in a future story
  };

  openErrorModal = (error, message) => {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);
      this.setState(() => ({
        errorModalMessage: message,
        showErrorModal: true,
      }));
    }
  };

  updateConversationStatus = (updatedConversation) => {
    try {
      Logger.log('updateConversationStatus', updatedConversation);
      this.twoWayConversationService.updateConversationStatus(
        updatedConversation
      );
      if (
        updatedConversation.conversationStatus ===
        Constants.TwoWayMessages.viewStatus.trash
      ) {
        this.setState((prevState) => {
          const { conversations } = prevState;
          let { conversation } = prevState;
          if (
            updatedConversation.conversationKey ===
            conversation?.conversationKey
          ) {
            conversation = null;
            this.props.history.push('/chat');
          }
          var iDeleted = conversations.findIndex(
            (c) => c.conversationKey === updatedConversation.conversationKey
          );
          if (iDeleted >= 0) {
            conversations.splice(iDeleted, 1);
            return { conversations: conversations, conversation: conversation };
          }
        });
      }
    } catch (error) {
      Logger.error(error);
      this.openErrorModal(
        error,
        this.props.t('There was an unexpected issue with saving resources.')
      );
    }
    this.getConversationStatusCounts();
  };

  conversationClick = (conversation) => {
    if (
      conversation.conversationStatus ===
      Constants.TwoWayMessages.viewStatus.unread
    ) {
      conversation.conversationStatus =
        Constants.TwoWayMessages.viewStatus.unanswered;
      this.updateConversationStatus(conversation);
    }
    this.setState(() => ({
      conversation: conversation,
    }));
    this.props.history.push('/chat/message/' + conversation.conversationKey);
    Events.emit(Constants.Events.clickConversation);
  };

  newMessage = () => {
    this.eBuilder
      .withCategory(this.eBuilder.Category.Chat)
      .withAction(this.eBuilder.Action.Chat.Click.clickNewChatMessage)
      .withLabel(this.eBuilder.Label.practiceIdentifier)
      .post();
    this.setState(() => ({
      conversation: null,
    }));
    this.props.history.push('/chat/new');
  };

  multiRecipient = () => {
    /* this code seems is not executed. I'll keep it to review it again we're working on the search*/
    this.openModalContent(
      'Multi-Recipient',
      'Search for existing resources (patients) by first and last name, or phone number.',
      [
        {
          action: this.closeModal,
          content: 'Search',
          key: 1,
        },
        {
          action: this.closeModal,
          color: 'primary',
          content: 'Submit',
          key: 2,
        },
      ]
    );
  };

  searchList = () => {};

  render() {
    const {
      loadingConversations,
      conversation,
      conversations,
      conversationContinuationToken,
      isReadyToRender,
      showLocationErrorModal,
      showErrorModal,
      errorModalMessage,
      lastRefreshed,
      loadingInterval,
      locationConfig,
      modalContent,
      defaultMessagingPref,
      isSmsOneWay,
      isWhatsAppNotificationsEnabled,
      phoneCountryCode,
      templateList,
      isStellestEnabled,
      conversationStatusCounts,
      featureConfiguration,
    } = this.state;
    const { t } = this.props;

    return (
      <div className="chat">
        {isReadyToRender && (
          <section className="menu">
            <AdminMenu
              location={locationConfig?.storeInformation.name}
              showAddAppointment={true}
              showHamburgerMenu={false}
              chatActive={true}
            />
          </section>
        )}

        {isReadyToRender && <TwoWayHeader today={momentLocaleWrapper()} />}

        {isReadyToRender && !showLocationErrorModal && (
          <div className="page-two-way__message">
            <TwoWayList
              conversationContinuationToken={conversationContinuationToken}
              conversationStatusCounts={conversationStatusCounts}
              filterList={this.filterList}
              loadMoreToList={this.loadMoreToList}
              refreshList={this.refreshList}
              conversation={conversation}
              conversations={conversations}
              updateConversationStatus={this.updateConversationStatus}
              conversationClick={this.conversationClick}
              newMessage={this.newMessage}
              multiRecipient={this.multiRecipient}
              loadingConversations={loadingConversations}
              searchList={this.searchList}
              lastRefreshed={lastRefreshed}
              loadingInterval={loadingInterval}
              templateList={templateList}
              defaultMessagingPref={defaultMessagingPref}
              isSmsOneWay={isSmsOneWay}
              isWhatsAppNotificationsEnabled={isWhatsAppNotificationsEnabled}
              countryCode={locationConfig?.storeInformation?.countryCode}
              phoneCountryCode={phoneCountryCode}
              errorModal={this.openErrorModal}
              conversationOptOut={this.conversationOptOut}
              locationConfigName={locationConfig?.storeInformation.name}
              locationConfigAddress={locationConfig?.storeInformation.address}
              locationConfigPhone={locationConfig?.storeInformation.phone}
            />
          </div>
        )}
        {featureConfiguration?.EnableAddPlusMenu && <AddDrawer />}
        {!featureConfiguration?.EnableAddPlusMenu && (
          <CalendarAddAppointmentDrawer />
        )}
        <StellestRegistrationForm />
        <section className="page-two-way__footer">
          <GenericFooter settingIsStellestEnabled={isStellestEnabled} />
        </section>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="admin-settings__modal-btn"
              onClick={this.closeErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
        {modalContent.open && (
          <TwoWayModal {...modalContent} closeModal={this.closeModal} />
        )}
      </div>
    );
  }
}

export default withTranslation()(TwoWayMessagePage);
