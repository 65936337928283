import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Constants from '../../constants';
import Enums from '../../enums';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import FeatureConfigurationService from '../../Services/featureConfigurationService';

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

class CustomerProfileDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      featureConfiguration: {},
    };

    this._featureConfigurationService = new FeatureConfigurationService();
  }

  async componentDidMount() {
    const featureConfiguration = await this._featureConfigurationService.getFeatureConfiguration(
      process.env.REACT_APP_ENVIRONMENT
    );
    this.setState(() => ({
      featureConfiguration: featureConfiguration,
    }));
  }

  _getHasVisualDemandsDescription = (preAppointmentSurvey) => {
    if (preAppointmentSurvey) {
      const currVisualDemands = [];
      preAppointmentSurvey.visualDemandDigitalDevice && currVisualDemands.push(true);
      preAppointmentSurvey.visualDemandReadWrite && currVisualDemands.push(true);
      preAppointmentSurvey.visualDemandNightDrive && currVisualDemands.push(true);

      return currVisualDemands;
    }
    return null;
  };

  _getFrequencyOfUseDescription = (preApptfrequencyOfUse) => {
    if (preApptfrequencyOfUse) {
      const { t } = this.props;
      let frequencyOfUse = '';
      if (preApptfrequencyOfUse == Constants.FrequencyOfUse.allTheTime) {
        frequencyOfUse = t('All the time');
      } else if (preApptfrequencyOfUse == Constants.FrequencyOfUse.sometimes) {
        frequencyOfUse = t('Sometimes');
      } else if (preApptfrequencyOfUse == Constants.FrequencyOfUse.farDistance) {
        frequencyOfUse = t('Only for far distance');
      } else if (preApptfrequencyOfUse == Constants.FrequencyOfUse.reading) {
        frequencyOfUse = t('Only for reading');
      } else {
        frequencyOfUse = t('Only for the computer');
      }
      return frequencyOfUse;
    }
    return null;
  };

  _getMessagingPlatformDescription = (messagingPlatform) => {
    const { t } = this.props;
    switch (messagingPlatform) {
      case Constants.MessagingPlatforms.WhatsApp: {
        return t('WhatsApp');
      }
      case Constants.MessagingPlatforms.SMS:
      default: {
        return t('SMS Texting');
      }
    }
  };

  _setIsEditing = (isEditing) => {
    this.setState(() => ({
      isEditing: isEditing,
    }));
  };

  _showSmsWarningCheck() {
    const { customer } = this.props;
    const { isEditing } = this.state;

    const isSmsOneWay =
      this.props.locationConfig?.countrySettings?.some(
        (cs) =>
          cs.settingName === Constants.CountrySettings.settingIsSmsOneWay &&
          cs.settingValue === true
      ) || false;
    const displaySmsOneWayWarning =
      isSmsOneWay && isEditing && customer.preferredPlatform === Enums.PreferredPlatform.smsText;
    return displaySmsOneWayWarning;
  }

  _onClose() {
    this.setState(() => ({
      isEditing: false,
    }));
    this.props.onClose();
  }

  _onSave(customer) {
    this.setState(() => ({
      isEditing: false,
    }));
    this.props.onSave(customer);
  }

  render() {
    const { t, isOpen, customer, onUpdate } = this.props;
    const { isEditing, featureConfiguration } = this.state;

    const hasVisualDemandsDescription = this._getHasVisualDemandsDescription(
      customer.latestPreAppointmentSurvey
    );
    const frequencyOfUseDescription = this._getFrequencyOfUseDescription(
      customer.latestPreAppointmentSurvey?.frequencyOfUse
    );
    const preferredPlatformDescription = this._getMessagingPlatformDescription(
      customer.preferredPlatform
    );

    return (
      <Drawer anchor="left" open={isOpen}>
        <div className="customers__drawer">
          <div className="customers__drawer-header">
            <h3 className="customers__drawer-title">{t('Customer Profile')}</h3>
            {featureConfiguration.EnableEditPatientMessagePlatform && (
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                disabled={isEditing}
                onClick={() => this._setIsEditing(true)}
              >
                {t('Edit')}
              </Button>
            )}

            <button className="customers__close-drawer" onClick={() => this._onClose()}>
              <CloseIcon />
            </button>
          </div>
          <div>
            <table className="customers__profile-table">
              <tbody>
                <tr>
                  <td>{t('First Name')}:</td>
                  <td>{customer.firstName}</td>
                </tr>
                <tr>
                  <td>{t('Last Name')}:</td>
                  <td>{customer.lastName}</td>
                </tr>
                <tr>
                  <td>{t('Phone')}:</td>
                  <td>{customer.phone}</td>
                </tr>
                <tr>
                  <td>{t('Text Message Preference')}:</td>
                  {!isEditing && <td>{preferredPlatformDescription}</td>}
                  {isEditing && featureConfiguration.EnableEditPatientMessagePlatform && (
                    <td>
                      <RadioGroup
                        row
                        value={customer.preferredPlatform}
                        onChange={(e) => {
                          const updatedValue = parseInt(e.target.value);
                          customer.preferredPlatform = updatedValue;
                          onUpdate(customer);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label={t('WhatsApp')}
                          labelPlacement="end"
                          value={Enums.PreferredPlatform.whatsApp}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={t('SMS Texting')}
                          labelPlacement="end"
                          value={Enums.PreferredPlatform.smsText}
                        />
                      </RadioGroup>
                    </td>
                  )}
                </tr>
                {this._showSmsWarningCheck() && (
                  <tr>
                    <td colSpan={'2'} className="customers__sms-one-way-warning">
                      <p>
                        {t(
                          'Notice: Please note that you are choosing SMS as the preferred channel of communication for this customer, this will prohibit you from communicating back and forth with them and limits you to 1 way messages only due to local restrictions.'
                        )}
                      </p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{t('Email')}:</td>
                  <td>{customer.email}</td>
                </tr>
                <tr>
                  <td>{t('Upcoming Appointment Day/Date')}:</td>
                  <td>
                    {customer?.nextAppointmentDate
                      ? momentLocaleWrapper(customer.nextAppointmentDate).format(
                          'ddd, MMM DD YYYY HH:mm'
                        )
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td className="customers__survey-cell">{t('Survey Answers')}:</td>
                  <td>
                    <ol className="customers__survey-answers">
                      <li>
                        <span className="customers__survey-question">
                          {t('Visual demands')}:&nbsp;&nbsp;
                        </span>
                        {hasVisualDemandsDescription && (
                          <ul>
                            {customer.latestPreAppointmentSurvey.visualDemandDigitalDevice && (
                              <li>
                                <span className="customers__survey-answer">
                                  {t('Digital devices')}
                                </span>
                              </li>
                            )}
                            {customer.latestPreAppointmentSurvey.visualDemandReadWrite && (
                              <li>
                                <span className="customers__survey-answer">
                                  {t('Read/Write newspaper/books')}
                                </span>
                              </li>
                            )}
                            {customer.latestPreAppointmentSurvey.visualDemandNightDrive && (
                              <li>
                                <span className="customers__survey-answer">
                                  {t('Night driving')}
                                </span>
                              </li>
                            )}
                          </ul>
                        )}
                      </li>
                      <li>
                        <span className="customers__survey-question">
                          {t('Age range')}:&nbsp;&nbsp;
                        </span>
                        {customer.latestPreAppointmentSurvey?.ageRange && (
                          <span className="customers__survey-answer">
                            {customer.latestPreAppointmentSurvey?.ageRange ==
                            Constants.AgeRange.lessThan40
                              ? t('Less than 40')
                              : t('Over 40')}
                          </span>
                        )}
                      </li>
                      <li>
                        <span className="customers__survey-question">
                          {t('Wear eyeglasses')}:&nbsp;&nbsp;
                        </span>
                        {customer.latestPreAppointmentSurvey &&
                          customer.latestPreAppointmentSurvey?.currentlyWearGlasses !== null && (
                            <span className="customers__survey-answer">
                              {customer.latestPreAppointmentSurvey?.currentlyWearGlasses
                                ? t('Yes')
                                : t('No')}
                              {customer.latestPreAppointmentSurvey?.frequencyOfUse &&
                                ' - ' + frequencyOfUseDescription}
                            </span>
                          )}
                      </li>
                      <li>
                        <span className="customers__survey-question">
                          {t('Special instructions')}:&nbsp;&nbsp;
                        </span>
                        <span className="customers__survey-answer">
                          {customer.latestPreAppointmentSurvey?.specialInstructions}
                        </span>
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="customers__drawer-footer">
            <button className="customers__close-btn" onClick={() => this._onClose()}>
              {t('Close')}
            </button>
            {featureConfiguration.EnableEditPatientMessagePlatform && (
              <button
                className="customers__save-btn"
                disabled={!isEditing}
                onClick={() => this._onSave(customer)}
              >
                {t('Save')}
              </button>
            )}
          </div>
        </div>
      </Drawer>
    );
  }
}
export default withTranslation()(CustomerProfileDrawer);
