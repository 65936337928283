import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import MuiIconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Enums from '../../enums';
import Utils from '../../utils';
import Events from '../../events';
import Session from '../../session';
import Storage from '../../storage';
import Constants from '../../constants';
import AuthService from '../../Services/authService';
import Overlay from '../../Components/Overlay/Overlay';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import AdminMenu from '../../Components/AdminMenu/AdminMenu';
import SettingsService from '../../Services/settingsService';
import SupportInfoService from '../../Services/supportInfoService';
import NotificationService from '../../Services/notificationsService';
import LocationConfigService from '../../Services/locationConfigService';
import SiteConfigService from '../../Services/siteConfigService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import Appointments from '../../Components/PerformanceDashboard/Appointments';
import StellestOrderTracker from '../../Components/StellestDashboard/StellestOrderTracker';
import './Dashboard.scss';
import RatingsAndReviews from '../../Components/PerformanceDashboard/RatingsAndReviews';
import CalendarAddAppointmentDrawer from '../../Components/CalendarAddAppointmentDrawer/CalendarAddAppointmentDrawer';
import AddDrawer from '../../Components/AddDrawer/AddDrawer';
import StellestRegistrationForm from '../../Components/StellestRegistrationForm/StellestRegistrationForm';
import GenericFooter from '../../Components/GenericFooter/GenericFooter';

const ListSubheader = withStyles({
  root: {
    fontSize: '0.9rem',
    paddingLeft: '2.2em',
  },
})(MuiListSubheader);

const ListItemText = withStyles({
  root: {
    fontSize: '0.9rem',
    paddingLeft: '2em',
  },
})(MuiListItemText);

class SettingsItemType {
  static storeInfo = 0;

  static storeHours = 1;

  static appointmentTypes = 2;

  static resources = 3;

  static qrCodeStorePoster = 4;

  static storefrontUrl = 5;

  static customerData = 6;

  static notifications = 7;

  static storeHolidays = 8;

  static customerFeedback = 9;

  static performanceDashboard = {
    appointments: 10,
    ratingsAndReviews: 11,
  };

  static stellestDashboard = {
    orderTracker: 12,
  };
}

const IconButton = withStyles({
  root: {
    padding: 0,
    color: '#FFFFFF',
  },
})(MuiIconButton);

/**
 * Represents the admin settings.
 */
class Dashboard extends Component {
  /**
   * Initializes a new instance of the Settings component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      activePane: 0,
      currSettingsItemType: SettingsItemType.performanceDashboard.appointments,
      dashboardShouldRender: false,
      errorModalMessage: '',
      isPerformanceDashboardEnabled: false,
      isReadyToRender: false,
      isStellestEnabled: false,
      isStoreInformationDirty: false,
      locationId: 0,
      showErrorModal: false,
      showLoadingOverlay: false,
      showNav: false,
      supportInfo: {
        email: '',
        phone: '',
      },
      uniqueLocationUrl: '',
      featureConfiguration: {},
    };
    this._hasPlaceId = false;
    this._holidays = [];
    this._resources = [];
    this._storeHours = [];
    this._appointmentTypes = [];
    this._storeInformation = {};
    this._locationSettings = [];
    this._settingsService = new SettingsService();
    this._supportInfoService = new SupportInfoService();
    this._notificationService = new NotificationService();
    this._locationConfigService = new LocationConfigService();
    this._siteConfigService = new SiteConfigService();
    this._featureConfigurationService = new FeatureConfigurationService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this.setState(() => ({ showLoadingOverlay: true }));

      try {
        const isAuthenticated = await AuthService.authenticateUser();

        if (!isAuthenticated) {
          AuthService.redirectToLogin();
          return;
        }
      } catch {
        AuthService.redirectToLogin();
        return;
      }

      this._setDateTimeLocale();
      await this._setI18nLanguage();
      await this._getLocationConfig();
      await this._getSupportInfo();
      await this._siteConfigService.getSiteConfig();
      const featureConfiguration =
        await this._featureConfigurationService.getFeatureConfiguration(
          process.env.REACT_APP_ENVIRONMENT
        );
      this.setState(() => ({
        featureConfiguration: featureConfiguration,
      }));

      this._setupSubscriptions();
      this._checkOnboardingStatus();

      Events.emit(Constants.Events.pageReady);

      this.setState((prevState) => ({
        currSettingsItemType: this.state.isStellestEnabled
          ? SettingsItemType.stellestDashboard.orderTracker
          : prevState.currSettingsItemType,
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);

        this.setState(() => ({
          isReadyToRender: false,
          showLoadingOverlay: false,
        }));
      }
    }
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.onLogout);
  }

  _checkOnboardingStatus = () => {
    if (!this.state.agreementSigned) {
      window.location = `/termsuse`;
    }
  };

  _getCurrSettingsItemComponent = () => {
    let currSettingsItemComponent = null;
    const { currSettingsItemType, locationId } = this.state;

    switch (currSettingsItemType) {
      case SettingsItemType.performanceDashboard.appointments: {
        currSettingsItemComponent = (
          <Appointments locationId={this.state.locationId} />
        );
        break;
      }
      case SettingsItemType.performanceDashboard.ratingsAndReviews: {
        currSettingsItemComponent = <RatingsAndReviews />;
        break;
      }
      case SettingsItemType.stellestDashboard.orderTracker: {
        currSettingsItemComponent = (
          <StellestOrderTracker locationId={locationId} />
        );
        break;
      }
      default: {
        currSettingsItemComponent = <Appointments />;
        break;
      }
    }

    return currSettingsItemComponent;
  };

  _getLocationConfig = async () => {
    try {
      const locationConfig =
        await this._locationConfigService.getLocationConfig();
      const {
        agreementSigned,
        appointmentTypes,
        countrySettings,
        locationSettings,
        resources,
        storeHours,
        placeId,
        storeInformation,
        uniqueLocationURL,
      } = locationConfig;

      Utils.formatAppointmentTypes(appointmentTypes);
      Utils.formatResources(resources);

      // Sort the resources based on their sort order.
      const sortedResources = resources.sort((res1, res2) =>
        res1.sortOrder > res2.sortOrder ? 1 : -1
      );

      this._hasPlaceId = !!placeId;
      this._storeHours = cloneDeep(storeHours);
      this._resources = cloneDeep(sortedResources);
      this._countrySettings = cloneDeep(countrySettings);
      this._locationSettings = cloneDeep(locationSettings);
      this._appointmentTypes = cloneDeep(appointmentTypes);
      this._storeInformation = cloneDeep(storeInformation);

      const isPerformanceDashboardEnabled =
        Session.getItem(Constants.currCountry) === 'CO';

      const stellestSettings = locationSettings.find(
        (e) =>
          e.displayName === Constants.LocationSettings.settingIsStellestEnabled
      );
      const isStellestEnabled = stellestSettings
        ? stellestSettings.settingValue
        : false;

      const dashboardShouldRender =
        isPerformanceDashboardEnabled || isStellestEnabled;

      this.setState(() => ({
        agreementSigned: agreementSigned,
        dashboardShouldRender: dashboardShouldRender,
        locationId: locationConfig.locationId,
        isPerformanceDashboardEnabled: isPerformanceDashboardEnabled,
        isStellestEnabled: isStellestEnabled,
        uniqueLocationUrl: uniqueLocationURL,
        stellestSettings: stellestSettings,
      }));
    } catch (error) {
      this._toggleErrorModal(
        error,
        true,
        this.props.t(
          'There was an unexpected issue with retrieving the settings'
        )
      );
    }
  };

  _getSupportInfo = async () => {
    try {
      const supportInfo = await this._supportInfoService.getSupportInfo(
        this._storeInformation.countryCode
      );

      this.setState(() => ({ supportInfo: cloneDeep(supportInfo) }));
    } catch (error) {
      this._toggleErrorModal(
        error,
        true,
        this.props.t(
          'There was an unexpected issue with retrieving the settings'
        )
      );
    }
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onSettingsItemClick = (settingsItemType) => {
    if (this.state.currSettingsItemType !== settingsItemType) {
      this.setState(() => ({ currSettingsItemType: settingsItemType }));
    }
  };

  _setDateTimeLocale() {
    momentLocaleWrapper.locale(Storage.getItem(Constants.langTag));
  }

  _setI18nLanguage = async () => {
    return this.props.i18n.changeLanguage(Storage.getItem(Constants.langTag));
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.onLogout, () => {
      AuthService.logoutUser();
    });
    Events.on(Constants.Events.locationChanged, async () => {
      await this._getLocationConfig();
      this._checkOnboardingStatus();
    });
  };

  _toggleErrorModal = (error, show, message) => {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);

      this.setState(() => ({
        showErrorModal: show,
        errorModalMessage: message,
      }));
    }
  };

  _onToggleNavigation = () => {
    this.setState((prevState) => {
      return {
        showNav: !prevState.showNav,
      };
    });
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      currSettingsItemType,
      dashboardShouldRender,
      errorModalMessage,
      isPerformanceDashboardEnabled,
      isReadyToRender,
      isStellestEnabled,
      stellestSettings,
      showErrorModal,
      showLoadingOverlay,
      showNav,
      supportInfo,
      featureConfiguration,
    } = this.state;
    const { email } = supportInfo;
    const { t } = this.props;
    const currSettingsItemComponent = this._getCurrSettingsItemComponent();

    if (isReadyToRender && !dashboardShouldRender) {
      return <Redirect to={{ pathname: `/schedule` }} />;
    }

    return (
      <div
        className={`admin-dashboard ${
          !showNav ? 'admin-dashboard__nav--collapsed' : ''
        }`}
      >
        {isReadyToRender && (
          <section className="admin-dashboard__menu">
            <AdminMenu
              location={this._storeInformation.name}
              showAddAppointment={false}
              showHamburgerMenu={false}
              onToggleCalendarPane={() => {}}
              dashboardActive = {true}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-dashboard__header">
            <IconButton
              className={`admin-dashboard__arrow ${
                showNav ? 'admin-dashboard__arrow--expanded' : ''
              }`}
              onClick={() => this._onToggleNavigation()}
            >
              <ChevronRightIcon
                className="admin-dashboard__arrow-icon"
                htmlColor={'black'}
                fontSize={'large'}
              />
            </IconButton>
            <h1 className="admin-dashboard__title">{t('Dashboard')}</h1>
            <h5 className="admin-dashboard__support">
              {`${t('Have Support Questions?')}`}{' '}
              <a href={`mailto:${email}`}>{`${email}`}</a>
            </h5>
          </section>
        )}
        {isReadyToRender && (
          <section className={`admin-dashboard__nav`}>
            {isPerformanceDashboardEnabled && (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    disableSticky={true}
                    id="appointments-subheader__performance"
                  >
                    {t('Performance')}
                  </ListSubheader>
                }
              >
                <ListItem
                  button
                  selected={
                    currSettingsItemType ===
                    SettingsItemType.performanceDashboard.appointments
                  }
                  onClick={() =>
                    this._onSettingsItemClick(
                      SettingsItemType.performanceDashboard.appointments
                    )
                  }
                >
                  <ListItemText primary={t('Appointments')} />
                </ListItem>
              </List>
            )}

            {isStellestEnabled && (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    disableSticky={true}
                    id="nested-list-subheader__stellest"
                  >
                    {t('Stellest')}
                  </ListSubheader>
                }
              >
                <ListItem
                  button
                  selected={
                    currSettingsItemType ===
                    SettingsItemType.stellestDashboard.orderTracker
                  }
                  onClick={() =>
                    this._onSettingsItemClick(
                      SettingsItemType.stellestDashboard.orderTracker
                    )
                  }
                >
                  <ListItemText primary={t('Stellest Care Management')} />
                </ListItem>
              </List>
            )}
          </section>
        )}
        {isReadyToRender && (
          <section className="admin-dashboard__content">
            {currSettingsItemComponent}
          </section>
        )}
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="admin-dashboard__modal-btn"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
        {featureConfiguration?.EnableAddPlusMenu && <AddDrawer />}
        {!featureConfiguration?.EnableAddPlusMenu && (
          <CalendarAddAppointmentDrawer />
        )}
        <StellestRegistrationForm />
        <section className="admin-dashboard__footer">
          <GenericFooter settingIsStellestEnabled={stellestSettings} />
        </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Dashboard);
