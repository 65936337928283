import React, { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import MuiTooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Enums from '../../enums';
import './MessageStatus.scss';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);
const getMessageStatusTitle = (status) => {
  switch (status) {
    case 'delivered':
      return 'delivered';
    case 'read':
      return 'read';
    case 'undelivered':
    case 'failed':
      return 'undelivered';
    default:
      return '';
  }
};

const getMessageStatusDescription = (status, code) => {
  if ('delivered' === status) {
    return (
      <span className="message-status-description">
        A status of <span className="delivered">delivered</span> means that the customer has
        successfully received this message.
      </span>
    );
  } else if ('read' === status) {
    return (
      <span className="message-status-description">
        A status of <span className="read">read</span> means that the customer has successfully read
        this message.
      </span>
    );
  } else if ('undelivered' == status || 'failed' == status) {
    switch (code) {
      case '21614':
        return (
          <span className="message-status-description">
            'To' number is not a valid mobile number
          </span>
        );
      case '21408':
        return (
          <span className="message-status-description">
            Permission to send an SMS has not been enabled for the region indicated by the 'To'
            number
          </span>
        );
      case '30003':
        return (
          <span className="message-status-description">
            The destination handset you are trying to reach is switched off or otherwise unavailable
          </span>
        );
      case '30008':
        return <span className="message-status-description"></span>;
      case '63016':
        return (
          <span className="message-status-description">
            Failed to send freeform message because you are outside the allowed window. Please use a
            templated message
          </span>
        );
      case '21614':
        return (
          <span className="message-status-description">
            Failed to send freeform message because you are outside the allowed window. Please use a
            templated message
          </span>
        );
      case '11200':
        return (
          <span className="message-status-description">
            There was a failure attempting to retrieve the contents of this URL/link
          </span>
        );
      case '21703':
        return (
          <span className="message-status-description">
            The phone number is not available to send this message.
          </span>
        );
      case '63003':
        return (
          <span className="message-status-description">
            'To' number is not a valid mobile number
          </span>
        );
      default:
        return <span className="message-status-description">This message was not delivered</span>;
    }
  } else {
    return <></>;
  }
};

const MessageStatus = ({ messageStatus, messageErrorCode, messagePlatform }) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  return (
    <div className="message-status">
      {(() => {
        if (messageStatus != null && messageStatus !== '') {
          return (
            <ClickAwayListener onClickAway={() => setShouldShowTooltip(false)}>
              <Tooltip
                className="message-status--tooltip"
                open={shouldShowTooltip}
                placement="top-end"
                title={getMessageStatusDescription(messageStatus, messageErrorCode)}
                arrow
              >
                <div
                  className={`title ${messageStatus}`}
                  onClick={(e) => setShouldShowTooltip(true)}
                >
                  {getMessageStatusTitle(messageStatus)}
                  <InfoOutlinedIcon></InfoOutlinedIcon>
                </div>
              </Tooltip>
            </ClickAwayListener>
          );
        } else if (messageStatus === '' && messagePlatform === Enums.PreferredPlatform.whatsApp) {
          return (
            <div>
              Sending
              <InfoOutlinedIcon></InfoOutlinedIcon>
            </div>
          );
        } else {
          return <div></div>;
        }
      })()}
    </div>
  );
};

export default withTranslation()(MessageStatus);
